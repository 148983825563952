export type JsonFunc = () => Promise<any>

/**
 * Handle requests that have no response data available (SyntaxError)
 * ensure that the data being at least an empty object
 *
 * @example
 * const json = () => res.json() // throw SyntaxError
 * catchEmptyResponse(json) // return {}
 */
export const catchEmptyResponse = async (jsonFunc: JsonFunc) => {
  let json: any = {}

  try {
    json = await jsonFunc()
  } catch (error: any) {
    if (error.name !== 'SyntaxError') {
      // forward error besides SyntaxError
      throw error
    }
  }

  return json
}

export default catchEmptyResponse
