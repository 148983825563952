import { ConfigProvider } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

export interface Theme {
  theme: 'beans'
}

export const themeColors: Record<Theme['theme'], string> = {
  beans: '#0075ff',
}

export const Theme = React.createContext<Theme>({
  theme: 'beans',
})

interface Props {
  children: React.ReactNode
}
export function ThemeProvider({ children }: Props) {
  const [theme, setTheme] = useState<Theme['theme']>('beans')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.querySelector('html')?.setAttribute('theme', theme)
    }
  }, [theme])

  useEffect(() => {
    ConfigProvider.config({
      theme: {
        primaryColor: themeColors[theme],
      },
    })
  }, [theme])

  return (
    <Theme.Provider value={{ theme: theme }}>
      <ConfigProvider pageHeader={{ ghost: false }}>{children}</ConfigProvider>
    </Theme.Provider>
  )
}

export const useTheme = () => {
  const theme = useContext(Theme)

  if (!theme) throw new Error('Theme context is not provided')

  return theme
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Provider: ThemeProvider,
  Context: Theme,
  useTheme: useTheme,
  colors: themeColors,
}
