import * as Sentry from '@sentry/browser'

import { APP_NAME, APP_VERSION, NODE_ENV, RELEASE_ENVIRONMENT, SENTRY_DSN } from 'config/constants'

if (NODE_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: RELEASE_ENVIRONMENT ?? 'development',
    release: `${APP_NAME}@${APP_VERSION}`,
  })
}
