import { useMutation } from '@tanstack/react-query'
import * as t from 'io-ts'

import useUser from 'auth/hooks/useUser'
import { createDecoder } from 'common/api/decode'
import useApi from 'common/hooks/useApi'

const SwitchBackResponse = t.type({
  redirect_url: t.string,
})

function useSwitchBack() {
  const api = useApi()
  const { switchBack } = useUser()
  const decode = createDecoder(SwitchBackResponse)

  return useMutation(() => decode(api.post('/auth/switch-back/')), {
    onSuccess: switchBack,
  })
}

export default useSwitchBack
