import * as t from 'io-ts'

import Auth from './Auth'
import UserData from './UserData'

export const User = t.type({
  auth: Auth,
  data: UserData,
})
export type User = t.TypeOf<typeof User>

export const AnonymousUser = {
  auth: { authenticated: false, impersonated: false, permissions: [] },
  data: { id: '', email: '', first_name: '', last_name: '', phone: '' },
}

export default User
