import 'dayjs/locale/en'
import 'dayjs/locale/pl'

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ChainedBackend from 'i18next-chained-backend'
import HTTP from 'i18next-http-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next'

import { __DEV__ } from 'config/constants'

import { LANGUAGES } from './languages'

const backends = __DEV__
  ? [
      HTTP,
      resourcesToBackend({
        dev: {
          translation: require('./locales.json'),
        },
      }),
    ]
  : [HTTP]

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: __DEV__,
    supportedLngs: Array.from(LANGUAGES.keys()),
    fallbackLng: __DEV__ ? 'dev' : false,
    // ChainedBackend
    backend: {
      backends: backends,
      backendOptions: [
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
    // Interpolation
    interpolation: {
      // not needed for react
      escapeValue: false,
    },
  })

export default i18n
