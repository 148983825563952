import * as t from 'io-ts'

import { Permissions } from './Permission'

export enum AUTHENTICATION {
  FORBIDDEN = 'forbidden',
  REQUIRED = 'required',
}

export const Auth = t.type({
  authenticated: t.union([t.boolean, t.null]),
  impersonated: t.boolean,
  permissions: Permissions,
})
export type Auth = t.TypeOf<typeof Auth>

export default Auth
