import { Alert } from 'antd'
import React from 'react'

import { RELEASE_ENVIRONMENT } from 'config/constants'

const alertStyle: React.CSSProperties = {
  background: `repeating-linear-gradient(-45deg, #ffec3d, #ffec3d 10px, #141414 10px, #141414 20px)`,
  height: 6,
  padding: 0,
}

function EnvironmentInfo() {
  if (RELEASE_ENVIRONMENT === 'production') {
    return null
  }

  return <Alert banner message="" showIcon={false} style={alertStyle} />
}

export default EnvironmentInfo
