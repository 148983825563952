import Cookies from 'js-cookie'

import UserContext from 'auth/context/UserContext'
import { API_REST_URL, API_VERSION } from 'config/constants'
import dayjs from 'config/dayjs'
import i18n from 'config/locales/i18n'

type RequestMethod = 'DELETE' | 'GET' | 'OPTIONS' | 'PATCH' | 'POST' | 'PUT'

export class API {
  private apiUrl = API_REST_URL.replace(/\/$/, '')

  private apiVersion = API_VERSION
  private userContext: UserContext

  constructor({ userContext }: { userContext: UserContext }) {
    this.userContext = userContext
  }

  private buildHeaders(extra: Record<string, string> = {}) {
    const csrfToken = Cookies.get('csrftoken')
    const headers: Record<string, string> = {
      Accept: 'application/json',
      'Accept-Language': i18n.language,
      Timezone: dayjs.tz.guess(),
    }

    if (csrfToken) {
      headers['X-CSRFToken'] = csrfToken
    }

    if (!extra?.['Content-Type']) {
      headers['Content-Type'] = 'application/json'
    }

    return headers
  }

  private buildUrl(endpoint: string) {
    if (endpoint.startsWith('/')) {
      return `${this.apiUrl}/${this.apiVersion}${endpoint}`
    }

    return endpoint
  }

  async getError(response: Response) {
    try {
      return await response.json()
    } catch {
      return response
    }
  }

  async request(
    method: RequestMethod,
    endpoint: string,
    config: RequestInit & { headers?: Record<string, string> } = {}
  ) {
    const url = this.buildUrl(endpoint)
    const headers = this.buildHeaders(config.headers)

    const response = await fetch(url, {
      credentials: 'include',
      headers,
      method,
      body: config.body,
    })

    if (!response.ok) {
      // This is an "interceptor" to logout the user if any of the API requests
      // respond with Forbidden status. Requests already check for permissions,
      // so it should only happen if the session is invalid or if permissions
      // were not set correctly in this application.
      // TODO move this to onError handler of all queries
      if (response.status === 403) {
        this.userContext.logout()
      }

      throw await this.getError(response)
    }

    return response
  }

  delete(endpoint: string) {
    return this.request('DELETE', endpoint)
  }

  get(endpoint: string) {
    return this.request('GET', endpoint)
  }

  options(endpoint: string) {
    return this.request('OPTIONS', endpoint)
  }

  patch<T>(endpoint: string, data?: T) {
    const config = data ? { body: JSON.stringify(data) } : undefined
    return this.request('PATCH', endpoint, config)
  }

  post<T>(endpoint: string, data?: T) {
    let config

    if (data instanceof FormData) {
      const headers: Record<string, string> = { 'Content-Type': 'multipart/form-data' }

      config = {
        body: data,
        headers,
      }
    } else if (data) {
      config = { body: JSON.stringify(data) }
    } else {
      config = undefined
    }

    return this.request('POST', endpoint, config)
  }

  put<T>(endpoint: string, data?: T) {
    const config = data ? { body: JSON.stringify(data) } : undefined
    return this.request('PUT', endpoint, config)
  }
}

export default API
