import { Layout } from 'antd'
import Head from 'next/head'
import React from 'react'
import { useTranslation } from 'react-i18next'

import stsx from 'common/utils/stsx'

import * as styles from './styles'

export interface FullscreenLayoutRenderProps {
  Content: ({ children }: { children: React.ReactNode; className?: string }) => React.ReactElement
}

interface Props {
  render: (props: FullscreenLayoutRenderProps) => React.ReactNode
  style?: React.CSSProperties
}

function FullscreenLayout({ render, style }: Props) {
  return (
    <Layout style={stsx(styles.layoutStyle, style)}>
      {render({
        Content: ({ children, className }) => (
          <Layout.Content style={styles.contentStyle} className={className}>
            {children}
          </Layout.Content>
        ),
      })}
    </Layout>
  )
}

interface LayoutProps {
  title?: string
  background?: string
  children: React.ReactNode
}

function InnerLayout({ children, title, background }: LayoutProps) {
  const { t } = useTranslation()

  return (
    <>
      <Head>{title && <title>{t(title)}</title>}</Head>
      <Layout style={stsx(styles.layoutStyle, { background: background })}>
        <Layout.Content style={styles.contentStyle}>{children}</Layout.Content>
      </Layout>
    </>
  )
}

export function createLayoutGetter({ title, background }: Omit<LayoutProps, 'children'>) {
  return function getLayout(page: React.ReactNode) {
    return (
      <InnerLayout title={title} background={background}>
        {page}
      </InnerLayout>
    )
  }
}

export default FullscreenLayout
