import * as E from 'fp-ts/Either'
import * as t from 'io-ts'
import reporter from 'io-ts-reporters'

import catchEmptyResponse from './catchEmptyResponse'

export function createDecoder<C extends t.Mixed>(codec: C) {
  return async function transformResponse(responsePromise: Promise<Response>): Promise<t.TypeOf<C>> {
    const response = await responsePromise
    const data = await catchEmptyResponse(() => response.json())
    const result = codec.decode(data)

    if (E.isRight(result)) {
      return Promise.resolve(result.right)
    } else {
      const error = reporter.report(result)
      return Promise.reject(new Error(error.join('\n')))
    }
  }
}
