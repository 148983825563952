import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'

import { API_GRAPHQL_URL } from 'config/constants'
import dayjs from 'config/dayjs'

const apiLink = createUploadLink({
  uri: API_GRAPHQL_URL,
  credentials: 'include',
  headers: { Timezone: dayjs.tz.guess() },
}) as any as ApolloLink

export const apolloClient = new ApolloClient({
  link: apiLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          inventoryItems: relayStylePagination(['$filter', '$sortBy', '$language', '$locationId']),
          manufacturers: relayStylePagination(['$filter', '$sortBy', '$language']),
          productFamilies: relayStylePagination(['$filter', '$sortBy', '$language']),
          navigationStores: relayStylePagination(),
          customerOrders: relayStylePagination(['$filter', '$sortBy', '$first', '$last', '$after', '$before']),
          productDrafts: relayStylePagination([
            '$filter',
            '$sortBy',
            '$first',
            '$last',
            '$after',
            '$before',
            '$language',
          ]),
          storeOrders: relayStylePagination(['$filter', '$sortBy', '$first', '$last', '$after', '$before']),
          inventoryLocations: relayStylePagination(['$filter', '$sortBy', '$first']),
          customers: relayStylePagination(['$filter', '$first', '$last', '$after', '$before', '$sortBy']),
          products: relayStylePagination(['$filter', '$storeId', '$language']),
          privateProducts: relayStylePagination(['$filter', '$storeId', '$language']),
          taxGroups: relayStylePagination(['$filter', '$sortBy']),
          categories: relayStylePagination(['$filter', '$sortBy', '$language', '$parentId']),
        },
      },
    },
  }),
})

export default apolloClient
