export const NODE_ENV = process.env.NODE_ENV
export const PUBLIC_URL = process.env.PUBLIC_URL

// react
export const APP_NAME = process.env.NEXT_PUBLIC_NAME
export const APP_VERSION = process.env.NEXT_PUBLIC_VERSION

// graphql
export const API_GRAPHQL_URL = process.env.NEXT_PUBLIC_API_GRAPHQL_URL

// algolia
export const ALGOLIA_APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID
export const ALGOLIA_SEARCH_KEY = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY

// api
export const API_REST_URL = process.env.NEXT_PUBLIC_API_REST_URL
export const API_VERSION = process.env.NEXT_PUBLIC_API_VERSION

// sentry
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN

// environment
export const RELEASE_ENVIRONMENT = process.env.NEXT_PUBLIC_RELEASE_ENVIRONMENT

// gtm
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID

// app conditions
export const isForDev = RELEASE_ENVIRONMENT === 'dev'

// node environment
export const __DEV__ = process.env.NODE_ENV !== 'production'
