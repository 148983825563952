import { Button as BaseButton, ButtonProps as BaseProps } from 'antd'
import clsx from 'clsx'
import React from 'react'

export interface Props extends Omit<BaseProps, 'type'> {
  type?: 'primary' | 'secondary' | 'ghost' | 'link' | 'dashed' | 'text' | 'default'
  addonPosition?: 'prefix' | 'suffix'
  numberAddon?: number
}

export const Button = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { type = 'primary', numberAddon, addonPosition = 'prefix', className, icon, ...rest } = props

  const classes = clsx(
    type === 'secondary' && 'beans-button--secondary',
    addonPosition === 'suffix' ? 'beans-button-suffix' : 'beans-button-prefix',
    className
  )

  return (
    <BaseButton
      type={type === 'secondary' ? 'default' : type}
      icon={numberAddon != null ? <span className="beans-button-number">{numberAddon}</span> : icon}
      className={classes}
      ref={ref}
      {...rest}
    />
  )
})

export default Button
