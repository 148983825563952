import useUser from 'auth/hooks/useUser'
import API from 'common/api'

function useApi() {
  const userContext = useUser()

  return new API({ userContext })
}

export default useApi
