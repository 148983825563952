import create from 'zustand'
import { devtools } from 'zustand/middleware'

import Cart from 'store/models/cart/Cart'

export interface CartStore {
  cart?: Cart | null
  setCart: (cart?: Cart | null) => void
  clear: () => void
}

const useCartStore = create<CartStore>(
  devtools(
    set => ({
      cart: null,
      setCart: cart => set({ cart }),
      clear: () => set({ cart: null }),
    }),
    { name: 'useCartStore' }
  )
)

export default useCartStore
