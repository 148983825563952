export const contentStyle: React.CSSProperties = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: 16,
}

export const layoutStyle: React.CSSProperties = {
  display: 'flex',
  minHeight: '100vh',
  overflow: 'auto',
}
