import { useApolloClient } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { QueryClient } from '@tanstack/react-query'
import produce from 'immer'
import React, { createContext, useCallback, useState } from 'react'

import { Permissions } from 'auth/models/Permission'
import User, { AnonymousUser } from 'auth/models/User'
import UserData from 'auth/models/UserData'
import useCartStore from 'common/stores/useCartStore'

interface LoginForm {
  username: string
  password: string
}

interface UserContext {
  impersonate: () => void
  login: (form?: LoginForm) => void
  logout: () => void
  setPermissions: (permissions: Permissions) => void
  setData: (data: UserData) => void
  switchBack: () => void
  user: User | null
}

const noop = () => {}
const UserContext = createContext<UserContext>({
  impersonate: noop,
  login: noop,
  logout: noop,
  setData: noop,
  setPermissions: noop,
  switchBack: noop,
  user: null,
})

const produceAuthenticatedUser = produce(AnonymousUser, (draft: User) => {
  draft.auth.authenticated = true
})

const produceImpersonatedUser = (user: UserContext['user']) =>
  produce(user ?? AnonymousUser, (draft: User) => {
    draft.auth.impersonated = true
  })

const produceUserWithData = (data: UserData) => (user: UserContext['user']) => {
  if (user) {
    return produce(user, (draft: User) => {
      draft.data = data
    })
  }

  return produce(AnonymousUser, (draft: User) => {
    draft.auth.authenticated = null
    draft.data = data
  })
}

const produceUserWithPermissions = (permissions: Permissions) => (user: UserContext['user']) => {
  if (user) {
    return produce(user, (draft: User) => {
      draft.auth.permissions = permissions
    })
  }

  return produce(AnonymousUser, (draft: User) => {
    draft.auth.authenticated = null
    draft.auth.permissions = permissions
  })
}

interface Props {
  children: React.ReactNode
  queryClient: QueryClient
}

export const UserProvider = ({ children, queryClient }: Props) => {
  const [user, setUser] = useState<UserContext['user']>(null)
  const clearCart = useCartStore(store => store.clear)
  const apolloClient = useApolloClient()

  const impersonate = useCallback(() => {
    console.debug(`[Auth] impersonating`)
    setUser(produceImpersonatedUser)
  }, [])

  const logout = useCallback(() => {
    console.debug(`[Auth] clearing user session`)

    setUser(AnonymousUser)
    clearCart()
    apolloClient.cache.reset()
    queryClient.clear()
    Sentry.setUser(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCart, queryClient])

  const login = useCallback(
    async (form?: LoginForm) => {
      console.debug(`[Auth] setting user session`)

      setUser(produceAuthenticatedUser)
      queryClient.refetchQueries(['permissions'])
      queryClient.refetchQueries(['algolia-api-key'])
    },
    [queryClient]
  )

  const setData = useCallback((data: UserData) => {
    console.debug(`[Auth] setting user data`)
    setUser(produceUserWithData(data))
    Sentry.setUser({ email: data.email })
  }, [])

  const setPermissions = useCallback((permissions: Permissions) => {
    console.debug(`[Auth] setting permissions`)
    setUser(produceUserWithPermissions(permissions))
  }, [])

  const switchBack = useCallback(() => {
    console.debug(`[Auth] clearing user session`)
    setUser(null)
    queryClient.clear()
  }, [queryClient])

  return (
    <UserContext.Provider value={{ impersonate, login, logout, setData, setPermissions, switchBack, user }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext
