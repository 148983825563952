import { Alert } from 'antd'
import { useRouter } from 'next/router'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import useSwitchBack from 'auth/api/useSwitchBack'
import useUser from 'auth/hooks/useUser'
import Button from 'theme/components/Button'

const buttonStyle: React.CSSProperties = {
  height: 'auto',
  padding: 0,
}
const containerStyle: React.CSSProperties = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
}

function ImpersonationInfo() {
  const { t } = useTranslation()
  const { user } = useUser()
  const { mutateAsync: switchBack } = useSwitchBack()
  const router = useRouter()

  const handleSwitchBack = useCallback(async () => {
    const data = await switchBack()
    const redirectUrl = data?.redirect_url ?? ''
    if (redirectUrl.startsWith('/')) {
      router.replace(redirectUrl)
    } else {
      if (typeof window !== 'undefined') {
        window.location.href = redirectUrl
      }
    }
  }, [switchBack])

  if (!user?.auth.impersonated || !user?.data.email) {
    return null
  }

  return (
    <Alert
      banner
      message={
        <span style={containerStyle}>
          <span>{t('auth.impersonate.message', { user: user?.data })}</span>
          <Button onClick={handleSwitchBack} style={buttonStyle} type="link">
            {t('auth.impersonate.switch_back')}
          </Button>
        </span>
      }
      showIcon
      type="warning"
    />
  )
}

export default ImpersonationInfo
