import * as t from 'io-ts'

export const UserData = t.type({
  id: t.string,
  email: t.string,
  first_name: t.string,
  last_name: t.string,
  phone: t.string,
})
export type UserData = t.TypeOf<typeof UserData>

export default UserData
