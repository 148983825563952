import { AlgoliaLanguage } from 'algolia/algolia'
import { NODE_ENV } from 'config/constants'

const dev_languages = new Map([['dev', 'development language']])

const languages = new Map([
  ['en', 'English'],
  ['pl', 'język polski'],
])

export type Language = 'en' | 'pl' | 'dev'

export const LANGUAGES = NODE_ENV === 'development' ? dev_languages : languages

export const algoliaLanguages: Array<AlgoliaLanguage> = ['en', 'pl']

export default LANGUAGES
