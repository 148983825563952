import { CSSProperties } from 'react'

type Style = CSSProperties | false | undefined | null

/**
 * utility for constructing style objects conditionally. The function is modeled on clsx.
 *
 * @see https://github.com/lukeed/clsx
 */
export const stsx = (...inputs: Style[]): CSSProperties =>
  inputs
    .filter(input => input !== false && input != null)
    .reduce((result, current) => ({ ...result, ...current }), {}) as CSSProperties

export default stsx
