import 'config/locales/i18n'
import 'common/utils/sentry'
import 'theme/theme.less'
import 'theme/index.less'

import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import React, { StrictMode, Suspense } from 'react'

import { UserProvider } from 'auth/context/UserContext'
import { AppPropsWithLayoutPage } from 'common/components/ApplicationWrapper'
import EnvironmentInfo from 'common/components/EnvironmentInfo'
import ImpersonationInfo from 'common/components/ImpersonationInfo'
import LoadingFallback from 'common/components/LoadingFallback'
import { apolloClient } from 'common/graphql/client'
import Theme from 'theme'

const ApplicationWrapper = dynamic(() => import('common/components/ApplicationWrapper'), { ssr: false })

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: (error: unknown) => {
        console.error(error)
        Sentry.captureException(error)
      },
      refetchOnWindowFocus: false,
    },
  },
})

function Application({ Component, pageProps }: AppPropsWithLayoutPage) {
  const getLayout = Component.getLayout ?? ((page: any, pageProps?: any) => page)

  return (
    <StrictMode>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Marketplace</title>
      </Head>
      <Suspense fallback={<LoadingFallback />}>
        <QueryClientProvider client={queryClient}>
          <ApolloProvider client={apolloClient}>
            <UserProvider queryClient={queryClient}>
              <Theme.Provider>
                <EnvironmentInfo />
                <ImpersonationInfo />
                <ApplicationWrapper Component={Component}>
                  {getLayout(<Component {...pageProps} />, pageProps)}
                </ApplicationWrapper>
              </Theme.Provider>
            </UserProvider>
          </ApolloProvider>
        </QueryClientProvider>
      </Suspense>
    </StrictMode>
  )
}

export default Application
